import React, { useState, useEffect } from "react";

import AdEngageLayout from "components/layout";
import CategoryOverview from "components/category_overview";
import { adEngagePath, dataLoadingPath, CURRENT_TOP_EVENT_KEY, TOP_EVENTS } from "utils/constants";
import { CategoryData } from "models/category";

import { getCategoryMetadataFromPath } from "utils/jsonUtils";
import { navigate } from "@reach/router";
import axios from "axios";
import {fetchCategoriesMetaData, fetchGlobalShareOfPlatform, fetchTopEvent} from "utils/ad_engage";
import classNames from "classnames";
import AdEngageButton from "components/ad_engage_button";
import * as styles from "components/category_overview/styles.module.scss";

const CategoryOverviewPublicAdEngage = (props: { category: string }) => {
  const { category } = props;
  const { categoryId, categoryName } = getCategoryMetadataFromPath(category)

  const [categoryData, setCategoryData] = useState<CategoryData>();
  const [loading, setLoading] = useState(true);

  const [subtitle, setSubtitle] = useState<string>("Last 7 Days • Sorted By Airings")
  const pageFooter = <div className={classNames(styles.topAdsPageFooter)}>
    <div className={"flex-col"}>
      <div className={classNames(styles.footerText, styles.nflFooter)}>
        <p>When your ad is on the line, access to real-time customer engagement is the only thing that counts.</p>
        <p><b>Contact one of our industry specialists to learn more.</b></p>
      </div>
      <div className={styles.nflDemoButton}>
        <AdEngageButton label="Request A Demo" gotoLink="/contact-us" href={"/contact-us"}
                          extraClassNames={classNames(styles.headerButton, styles.demoButton)}/>
      </div>
    </div>
  </div>
  // eventually move this to a utils file
  const fetchCategoryData = () => {
    if (category === "share-of-platform") {
      axios?.get(dataLoadingPath + `global_share_of_platform.json`)
        .then((res: { data?: CategoryData }) => {
          if (typeof res?.data != "object") {
            throw new Error("No data");
          }
          setCategoryData(res?.data);
        }).catch((err: Error) => {
          // redirect back to parent category if no data for brand
          navigate(adEngagePath + "/");
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    if (category === TOP_EVENTS[CURRENT_TOP_EVENT_KEY].path) {
      fetchTopEvent()
        .then((data) => { setCategoryData(data); })
        .catch((err) => { console.log(err) })
        .finally(() => { setLoading(false); });
      return;
    }

    axios?.get(dataLoadingPath + `categories/category_${categoryId || ""}_metrics.json`)
      .then((res: { data?: CategoryData }) => {
        if (typeof res?.data != "object") {
          throw new Error("No data");
        }
        setCategoryData(res?.data);
      }).catch((err: Error) => {
        // redirect back to parent category if no data for brand
        navigate(adEngagePath + "/");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCategoryData()
  }, []);

  useEffect(() => {
    fetchCategoryData()
  }, [category]);


  console.log(subtitle);
  return (
    <AdEngageLayout pageTitle={categoryData?.name ? categoryData?.name + " - Category Overview" : "Category Overview"}>
      <CategoryOverview
        currentPath={category || ""}
        title={categoryData?.name}
        categoryName={categoryData?.name}
        subtitle={subtitle}
        content={categoryData?.metrics}
        contentLoading={loading}
        pageFooter={pageFooter}
      />
    </AdEngageLayout>
  )
};

export default CategoryOverviewPublicAdEngage;
